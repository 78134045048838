export default [
  {
    text: 'stocks',
    icon: 'trending_up',
    link: '/stocks',
    roles: ['SUPERADMIN'],
  },
  {
    text: 'indices',
    icon: 'view_list',
    link: '/indices',
    roles: ['SUPERADMIN'],
  },
  {
    text: 'watcher',
    icon: 'visibility',
    link: '/watch',
    roles: ['SUPERADMIN'],
  },
  {
    text: 'funds',
    icon: 'library_books',
    link: '/funds',
    roles: ['ADMIN', 'KVG_USER'],
  },
  {
    text: 'bonds',
    icon: 'date_range',
    children: [
        {
            text: 'bondsActivations',
            icon: 'date_range',
            link: '/bonds-activations',
            roles: ['ADMIN','SUPERADMIN'],
        },
        {
            text: 'bonds',
            icon: 'date_range',
            link: '/bonds',
            roles: ['ADMIN'],
        },
    ],
    roles: ['ADMIN'],
  },
  {
    text: 'equivalenceCheck',
    icon: 'check',
    link: '/equivalence-check',
    roles: ['SUPERADMIN'],
  },
  {
    text: 'users',
    icon: 'people',
    link: '/users',
    roles: ['SUPERADMIN'],
  },
  // {
  //   heading: 'Heading',
  // },
  /*{
    text: 'Group',
    icon: 'group',
    children: [
      {
        text: 'One thing',
        link: '/example',
        icon: 'add',
      },
      {
        text: 'And another',
        link: '/example2',
        icon: 'remove',
      },
    ],
  },*/
]

