import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import actions from "@/store/actions/actions"
import mutations from "@/store/mutations/mutations"
import getters from "@/store/getters/getters"

Vue.use(Vuex)

// state management
// > add new required state entries here
// > keep as flat as possible
// > clear cookies to reset to default
// > when adding mutations/getters/actions, make sure to update
//   the corresponding file (e.g. getters/getters.js)

// https://vuex.vuejs.org/guide/

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    locale: 'de',
    layout: {
      sidebarLeft: true,
      sidebarRight: false,
      dark: false,
      zoom: 1,
      snackbar: {
        text: null,
        type: null,
        icon: null,
        customIcon: null,
        show: false,
      },
    },
    auth: {
      token: null,
      refreshToken: null,
      user: null,
    },
    stock: {
      industryFields: [],
    },
    funds: [],
  },
  actions,
  mutations,
  getters,
})

export default store
