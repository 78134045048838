import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

// Here be theming options. Change the colors to give your app a unique feel!

// CSS variable can be used, see
// https://vuetifyjs.com/en/customization/theme/#custom-properties

export default new Vuetify({
  lang: {
    locales: { de, en },
    current: 'de',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#01579b',
        secondary: '#b0bec5',
        accent: '#00bfa5',
        focus: '#d2a911',
        error: '#a02525',
        success: '#4c8f4e',
        warning: '#FFC107',
        nav: '#01579b',
      },
      dark: {
        primary: '#01579b',
        secondary: '#848e92',
        accent: '#00bfa5',
        focus: '#d2a911',
        error: '#a02525',
        success: '#4c8f4e',
        warning: '#FFC107',
        nav: '#01579b',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
})
