import axios from 'axios'

export default {

  /**
   *
   * @param context
   * @param limit
   * @param offset
   * @param search
   * @param kvg
   * @returns {Promise<AxiosResponse<any>>}
   */
  initFundQuerySession(context, {search, kvg, assetClass}) {
    const url = '[citrus-broker]/app/derifund/fund'
    let data = {
      criteria: [
        {
          key: 'meta.active',
          operator: 'EQUALS',
          value: 1,
        },
      ],
      fields: [],
    }

    if(search) {
      data.criteria.push(
        {
          "key": "derived.searchString",
          "operator": "LIKE",
          "value": search.toUpperCase().trim(),
        },
      )
    }

    if(kvg){
      data.criteria.push(
        {
          key: 'derived.issuer.identifier',
          operator: 'EQUALS',
          value: kvg.toUpperCase().trim(),
        },
      )
    }

    if(assetClass){
      data.criteria.push(
        {
          key: 'derived.assetClass',
          operator: 'EQUALS',
          value: assetClass.toUpperCase().trim(),
        },
      )
    }

    return axios.post(url, data).then(res => {
      if(res) {
        return res.data.data
      }
    })
  },

  countProductsBySession(context, sessionId) {
    const url = '[citrus-broker]/app/derifund/fund/' + sessionId + '/count'
    return axios.get(url).then(res => {
      if(res) {
        return res.data.data
      }
    })
  },

  getProductsBySession(context, {limit, offset, sessionId}) {
    const url = `[citrus-broker]/app/derifund/fund/${sessionId}/data?limit=${limit}&offset=${offset}`
    return axios.get(url).then(res => {
      if(res) {
        console.log(res)
        return res.data.data
      }
    })
  },

  async fetchFund(context, dq) {
    const url = '[citrus-broker]/app/derifund/fund/'
    const sessionId = await axios
      .post(
        url, 
        {
          "criteria": [
            {
              "key": "dq",
              "operator": "EQUALS",
              "value": dq,
            },
          ],
          "fields": ["id", "ids", "name", "derived.issuer.identifier"],
        },
      ).then(res => res.data);

      return this.getProductsBySession(context, {"sessionId": sessionId, "limit": 1, "offset": 0})
  },

  /**
   *
   * @param context
   * @param dq
   * @param type
   * @param file
   * @param reportDate
   * @returns {Promise<AxiosResponse<any>>}
   */
  uploadFile(context, {dq, type, file, reportDate}) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    formData.append('reportDate', reportDate)

    const url = `[citrus-broker]/app/report/${dq}`
    return axios.post(url,formData)
  },

  uploadFileSimple(context, {file}) {
    let formData = new FormData()
    formData.append('file', file)

    const url = `[citrus-broker]/api/file`
    return axios.post(url,formData)
  },

  getEquivalenceCheckData(context, dq){
    const url = `[citrus-broker]/app/derifund/equivalence-check/${dq}`
    return axios.get(url)
      .then(res=> {
        return res.data.data
      })
  },

  updateEquivalenceCheckData(context, {dq, data}){
    const url = `[citrus-broker]/app/derifund/equivalence-check/${dq}`
    return axios.post(url, data)
      .then(res=> {
        return res.data.data
      })
  },

  saveAd(context, {dq, data}){
    const url = `[citrus-broker]/app/advt/product/${dq}`
    return axios.post(url, data)
      .then(res=> {
        return res.data.data
      })
  },

  /**
   *
   * @param context
   * @param dq
   * @param type
   * @returns {Promise<AxiosResponse<any>>}
   */
  invalidateFile(context, {dq, type}) {
    const url = `[citrus-broker]/app/report/${dq}/${type}/invalidate`
    return axios.put(url)
  },

  fetchFractions(context, dq){
    const url = `[citrus-broker]/api/fractions/${dq}`
    return axios.get(url).then(res => {
      return res.data.data
    }).catch(() => {
      return null
    })
  },

  updateFractions(context, {dq, data}){
    const url = `[citrus-broker]/api/fractions`
    return axios.post(url, data).then(res => {
      return res.data.data
    })
  },

  fetchAdvt(context, dq){
    const url = `[citrus-broker]/app/advt/product/${dq}`
    return axios.get(url).then(res => {
      return res.data.data
    }).catch(() => {
      return null
    })
  },

  fetchContacts(context, dq){
    const url = `[citrus-broker]/app/advt/product/${dq}/available-contacts`
    return axios.get(url).then(res => {
      return res.data.data
    }).catch(() => {
      return null
    })
  },
  fetchFiles(context, dq){
    const url = `[citrus-broker]/app/advt/product/${dq}/available-contacts`
    return axios.get(url).then(res => {
      return res.data.data
    }).catch(() => {
      return null
    })
  },
}
