import axios from 'axios'
import * as urlParse from 'url-parse'

// Auth Interceptor
// automatic injection of DUSE token if applicable

const setupInterceptors = function(router, store) {

  axios.interceptors.request.use(config => {
    const url = urlParse(config.url)

    if(
      url.pathname.includes('api/citrus')
      || url.pathname.includes('token/me')
    ) {
      config.headers = {
        ...config.headers,
        'Authorization': 'Bearer ' + store.state.auth.token,
        retry: config.headers.retry ?? true,
      }
    }

    return config
  }, error => {
    return Promise.reject(error)
  })

// Url Interceptor
// fills in urls from .env file

  axios.interceptors.request.use(config => {
    config.url = config.url.replace('[duse]', '/api/duse')
    config.url = config.url.replace('[citrus-broker]', '/api/citrus')

    if (process.env.NODE_ENV === 'development') {
      console.log('%c[interceptor]' + '%c ' + config.method.toUpperCase() + ' ' + config.url, 'background: #222; color: #0063b9', 'background: #222; color: green')
    }

    return config
  }, error => {
    return Promise.reject(error)
  })

// if token is expired, try again with refresh token

  axios.interceptors.response.use(config => {
    return config
  }, error => {
    if (error.response) {
      if (process.env.NODE_ENV === 'development') {
        console.log('%c[broker-ui interceptor] ' + '%c----ERROR----', 'color: blue', 'color: red')
        console.log(error)
      }


      if (
        error.config
        && error.config.headers.retry === true
        && ([401 ,403].includes(error.response.status))
      ) {
        return store.dispatch('refreshToken').then(() => {
          if (store.state.auth.token) {
            error.config.headers = {
              ...error.config.headers,
              retry: false,
            }
            console.log('retrying with new token')
            return axios.request(error.config)
          }
        }, error => {
          console.log(error)
        })
      } else {
        if(!error.config.headers.retry) {
          if(error.response.status === 400) {
            store.dispatch('showSnackbar', {text: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich neu an.', type: 'error'})
            store.commit('LOG_OUT')
            router.push('login')
          }
          router.push('error')
        }
      }
    } else if (error.request) {
      console.log(error.request)
    } else {
      console.log(error.message)
    }
  })

}

export default setupInterceptors
