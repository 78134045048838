import axios from 'axios'

export default {
  fetchSingleStock(context, dq) {
    const url = `[citrus-broker]/app/deristock/stock/${dq}`
    return axios.get(url)
  },

  fetchMultipleStocks(context, dqs) {
    const url = '[citrus-broker]/app/deristock/stock/'
    return axios.post(url, dqs)
  },

  addStock(context, stock) {
    const url = '[citrus-broker]/app/deristock/stock/insert?activate=true'
    return axios.post(url, [stock])
  },

  deleteStock(context, dq) {
    const url = `[citrus-broker]/app/deristock/stock/${dq}`
    return axios.delete(url)
  },

  fetchStocksByQueryFilter(context, {search, page, limit, fields}) {
    if (fields == null) fields = []

    const url = '[citrus-broker]/app/deristock/stock/filter'
    const params = {
      sort: {sorting: {name: "ASCENDING"}},
      page: {limit: limit, offset: limit * (page - 1)},
      fields: fields,
      criteria: [{key: "derived.searchMask", operator: "LIKE", value: search}],
    }

    return axios.post(url, params)
  },

  countStocksByQueryFilter(context, search) {
    const url = '[citrus-broker]/app/deristock/stock/filter/count'
    const params = {
      criteria: [{key: "derived.searchMask", operator: "LIKE", value: search}],
    }

    return axios.post(url, params)
  },

  activateStock(context, dq) {
    const url = `[citrus-broker]/app/deristock/derivation/stock/${dq}/activate`
    return axios.get(url)
  },

  fetchIndustryFields(context) {
    const url = '[citrus-broker]/app/deristock/stock/industryfield'
    let promise = axios.get(url)
    promise.then(response => {
      if (response.data.data instanceof Array) {
        context.commit('SET_INDUSTRY_FIELDS', response.data.data)
      }
    })
    return promise
  },

  fetchVWDCodes(context, id) {
    const url = `[citrus-broker]/app/deristock/stock/vwd/${id}`
    return axios.get(url)
  },
}
