import en from 'vuetify/lib/locale/en'

const message = {
  ...en,
  login: {
    login: 'Login',
    loginFailed: 'Login failed',
  },
  home: {
    greeting: '',
    error: 'There has been an error while loading your data. If this error continues, please contact support.',
  },
  settings: {
    language: 'Language',
    darkMode: 'Dark mode',
  },
  sidebar: {
    dashboard: 'Dashboard',
    stocks: '@:stocks.stocks',
    indices: '@:indices.indices',
    watcher: '@:watcher.watchers',
    funds: '@:funds.funds',
    bonds: '@:bonds.bonds',
    bondsActivations: '@:bonds.activations',
    users: '@:user.users',
    equivalenceCheck: '@:ec.equivalenceCheck',
  },
  ec: {
    equivalenceCheck: 'Equivalence check',
    typeSelector: 'Select product type',
  },
  user: {
    user: 'User',
    users: 'Users',
    username: 'Username',
    password: 'Password',
    first: 'First',
    last: 'Last',
    organization: 'Organization',
    email: 'Email',
    createdAt: 'Created',
    active: 'Active',
    deleted: 'Deleted',
  },
  actions: {
    create: 'Create',
    createX: `Create {x}`,
    add: 'Add',
    addX: 'Add {0}',
    save: 'Save',
    reset: 'Reset',
    delete: 'Delete',
    deleteX: 'Delete {0}',
    search: 'Search',
    showOnlySelected: 'Show only selected',
    update: 'Update',
    upload: 'Upload',
    saved: 'Changes have been saved',
    error: 'An error has occured',
  },
  snackbar: {
    errorOnSave: 'An error occurred while saving.',
  },
  stocks: {
    stocks: 'Stocks',
    stock: 'Stock',
    productIsActive: 'Produkt is active',
    productStatusUnknown: 'Product state (yet) unknown.',
    productIsInactive: 'Product is inactive. Reason(s): ',
    useSearchBar: 'Use the search bar above to search by stock name, ISIN or WKN.',
    industryField: 'Industry Field',
    expirationDate: 'Expiration date',
    deleteMessage: '<strong>Do you really want to delete the selected stock?</strong> <br> This cannot be undone!',
  },
  funds: {
    funds: 'Funds',
    assetClass: 'Asset class',
    assetClasses: {
      all: "All asset classes",
      BOND: "Bond",
      EQUITY: "Equity",
      MIXED: "Mixed",
      REAL_ESTATE: "Real estate",
      MONEY_MARKET: "Money market",
      LIFECYCLE: "Lifecycle",
      COMMODITIES: "Commodities",
      GUARANTEE: "Guarantee",
      OTHERS: 'Others',
    },
    searchLabel: 'Search term',
    reports: {
      documents: 'Documents',
      annual: 'Annual report',
      semiAnnual: 'Semi-annual report',
      prospect: 'Prospect',
      kid: 'BIB (PRIIP KID)',
      beiblatt: 'Beiblatt',
      factsheet: 'Factsheet',
      presentation: 'Presentation',
      reportDate: 'Report date: ',
      selectFile: 'Select file',
      openFile: 'Open file',
      removeFile: 'Remove file',
      selectDate: 'Select report date',
      uploadSuccess: 'Successfully uploaded file "{0}" ',
      invalidateSuccess: 'Successfully removed {0} ',
      uploadNewFile: 'Upload new document',
      isCustomUpload: 'This document was uploaded by a user',
      isBasicUpload: 'This document is provided by FWW.',
      uploadError: 'Upload failed.',
    },
    fractions: {
      error: 'An error has occured while saving',
      saved: 'Product data has been saved',
      sumCondition: 'The sum must 100%.',
      total: 'Total',
      liquidity: 'Liquidity',
      bonds: 'Bonds',
      stocks: 'Stocks',
      real_estate: 'Real estate',
      commodities: 'Commodities',
      holdings: 'Holdings',
      others: 'Others',
      fractions: 'Fractions',
    },
    sales: {
      support: 'Sales Support',
      comment: 'Management Comment (source: asset manager)',
      investmentStrategy: 'Investment strategy (source: asset manager)',
      contacts: 'Contacts',
      files: 'Files',
      addContact: 'Add contact',
      label: 'Label',
      labelPrompt: 'Please add a label',
    },
    ec: {
      equivalenceCheck: 'Equivalence check',
      saved: 'Equivalence data saved',
      bviFundCategory: 'BVI fund category',
      currencyHedge: 'Currency hedge',
      dq: 'DQ',
      objective: 'Objective',
      capitalProtection: 'Capital protection',
      targetYield: 'Target yield',
      recommendedHoldingPeriod: 'Recommended holding period',
      riskBasedManagement: 'Risk based management',
      stockNeutralPercentage: 'Stock neutral percentage',
      strategy: 'Strategy',
      fundamental: 'Fundamental',
      quantitative: 'Quantitative',
      thematic: 'Thematic',
      sustainability: 'Sustainability',
      sustainabilityInfo: 'Sustainability info',
      sustainabilityRiskInfo: 'Sustainability risk info',
    },
  },
  bonds: {
    bonds: 'Bonds',
    activations: 'Bond Activations',
    saved: 'Bond has been saved',
    saveError: 'Error while saving bond',
    startSubscriptionDate: 'Start subscription date',
    endSubscriptionDate: 'End subscription date',
    searchError: 'Bond could not be found',
    price: 'Price',
    issueSurcharge: 'Issue surcharge',
    isin: 'ISIN',
    issuer: 'Issuer',
    name: 'Name',
    issueDate: 'Issuedate',
    intermediary: 'Intermediary',
    tailorMade: 'Tailormade',
    month: 'Month',
    year: 'Year',
    filter: 'Filter for Issueday',
  },
  indices: {
    indices: 'Indices',
    index: 'Index',
    listedStocks: 'Listed stocks',
    addStockHint: 'Enter DQ directly or search by Name/WKN/ISIN',
    invalidInput: 'Invalid inputs! Please verify that name, wkn and isin are set correctly.',
    productExistsAlready: 'Error: This product already exists, please update it instead!',
    successfulCreation: 'Successfully created index "{0}" (DQ: {1})',
    errorOnCreation: 'An error occurred during index creation. Please try again later.',
    successfulDelete: 'Successfully deleted index!',
    errorOnDelete: 'An error occurred while trying to delete the selected index. Please try again later.',
    successfulUpdate: 'Successfully updated index "{0}"',
    errorOnUpdate: 'An error occurred during index update. Please try again later.',
    deleteMessage: '<strong>Do you really want to delete the selected stock index?</strong> <br> This cannot be undone!',
  },
  watcher: {
    watchers: 'Watchers',
    watcher: 'Watcher',
    instrumentType: 'Instrument type',
    validUntil: 'Valid until',
    ignoreUntil: 'Ignore until',
    productWatcherInformation: 'Product/Watcher information',
    productState: 'Product state',
    productStateInfo: 'Product state: {0} (last check: {1})',
    latestCheck: 'Last check',
    productType: 'Product type',
    productMessage: 'Product message',
    modeDescriptions: ['Equivalence data available [FUNDS]', 'Known certificate type [DERIVATES]'],
    invalidInput: 'Invalid inputs! Please make sure that product DQ and all dates are valid.',
    successfulCreation: 'Watcher created successfully!',
    errorOnCreation: 'An error occurred during product watcher creation. Please try again later.',
    deleteMessage: '<strong>Do you really want to delete the selected product watcher?</strong> <br> This cannot be undone!',
    successfulDelete: 'Watcher deleted successfully!',
    errorOnDelete: 'An error occurred while trying to delete the selected product watcher. Please try again later.',
    successfulUpdate: 'Watcher updated successfully!',
    errorOnUpdate: 'An error occurred during product watcher update. Please try again later.',
  },
  search: {
    search: 'Search',
    results: 'Search results',
    noResultsText: 'No {0} found.',
  },
  validation: {
    notEmpty: 'Value must not be empty',
    notEmptyX: '[0] must not be empty',
    containXCharacters: '[0] must contain [1] characters',
    invalidFormat: 'Invalid format',
  },
  notification: {
    loading: 'Loading..',
    noProductsFound: 'No data found! Please modify your search and try again.',
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  country: 'Country',
  currency: 'Currency',
  yes: 'Yes',
  no: 'No',
}

export default message
