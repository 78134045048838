import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/store"
import setupInterceptors from "@/utlity/interceptors"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/Home'),
 },
 {
   path: '/bonds',
   name: "bonds",
   component: () => import('../components/bonds/Bonds'),
 },
 {
   path: '/bonds-activations',
   name: "bonds-activations",
   component: () => import('../components/bonds/BondsActivations'),
 },
  {
    path: '/funds',
    name: "funds",
    component: () => import('../components/funds/Funds'),
  },
  {
    path: '/stocks',
    name: "stocks",
    component: () => import('../components/stocks/Stocks'),
  },
  {
    path: '/indices',
    name: "indices",
    component: () => import('../components/indices/Indices'),
  },
  {
    path: '/users',
    name: "users",
    component: () => import('../components/users/Users'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../components/Login'),
  },
  {
    path: '/watch',
    name: 'watch',
    component: () => import('../components/watch/Watch'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../components/Error'),
  },
  {
    path: '/equivalence-check',
    name: 'EquivalenceCheck',
    component: () => import('../components/equivalence-check/EquivalenceCheck'),
  },
]

function getRouter() {

  const router = new VueRouter({
    routes,
  })

  // check if logged in before every reroute
  router.beforeEach((to, from, next) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('%c[router from | to]' + '%c', 'background: #222; color: orange', 'background: #222; color: green')
      console.log(from)
      console.log(to)
      console.log('%c[router end]' + '%c', 'background: #222; color: orange', 'background: #222; color: green')
    }

    const isLoggedIn = store.getters.isLoggedIn

    if(!isLoggedIn && to.path !== '/login') {
      next('login')
    } else {
      if(isLoggedIn && to.path === '/login'){
        next(from.path)
      }
      next()
    }
  })

  setupInterceptors(router, store)

  return router
}


export default getRouter
