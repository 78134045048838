import axios from 'axios'

export default {
  listActiveWatchers() {
    const url = '[citrus-broker]/app/deriwatch/'
    return axios.get(url)
  },

  addWatcher(context, watcher) {
    const url = '[citrus-broker]/app/deriwatch/'
    return axios.post(url, watcher)
  },

  deleteWatcher(context, watcherId) {
    const url = `[citrus-broker]/app/deriwatch/${watcherId}`
    return axios.delete(url)
  },

  updateWatcher(context, watcherId) {
    const url = `[citrus-broker]/app/deriwatch/update/${watcherId}`
    return axios.get(url)
  },
}
