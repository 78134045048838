import axios from 'axios'

export default {

  fetchBond(context, dq) {
    const url = '[citrus-broker]/app/bond/' + dq
    return axios.get(url).then(res => {
      return res.data.data
    })
  },

  updateBond(context, data) {
    const url = `[citrus-broker]/app/bond`
    return axios.post(url, data)
      .then(res => {
        return res.data.data
      })
  },

  filterActivatedBonds(context, data) {
    const url = '[citrus-broker]/app/bond/primary/activated'
    return axios.post(url, data).then(res => {
      return res.data
    })
  },

}
