<template>
  <v-app-bar
    class="app-bar"
    app
    dark
    clipped-left
    dense
  >
    <!--    <v-app-bar-nav-icon @click.stop="$store.commit('TOGGLE_SIDEBAR_LEFT')" />-->
    <v-toolbar-title
      style="width: 300px"
    >
      <div>
        <img
          style="vertical-align: middle"
          class="pr-1"
          src="/dericon_logo_w.svg"
          height="16"
        />
        <span
          style="vertical-align: middle"
        >
          Data & Documents
        </span>
      </div>
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      icon
      @click="$store.commit('TOGGLE_SIDEBAR_RIGHT')"
    >
      <v-icon>settings</v-icon>
    </v-btn>
    <v-btn
      icon
      @click="logout()"
    >
      <v-icon>power_settings_new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'AppBar',
    data: () => {
      return {
        drawer: {
          type: Boolean,
          default: true,
        },
      }
    },
    methods: {
      logout() {
        this.$store.commit('LOG_OUT')
        this.$router.push('/login')
      },
    },
  }
</script>

<style lang="scss">

.app-bar {
  color: #fff;
  background: #141E30; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #243B55, #141E30); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .v-input .v-input__control {
    min-height: 35px;
  }
}

.theme--dark.v-navigation-drawer {
  background-color: #242424 !important;
}
</style>

<!--<style scoped lang="scss">-->
<!--  .app-bar {-->
<!--    &.theme&#45;&#45;light {-->
<!--      background-color: var(&#45;&#45;v-primary-base)-->
<!--    }-->
<!--    &.theme&#45;&#45;dark {-->
<!--      background-color: var(&#45;&#45;v-secondary-base)-->
<!--    }-->
<!--  }-->
<!--</style>-->
