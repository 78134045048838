<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    right
    temporary
  >
    <v-subheader>{{ $t('settings.language') }}</v-subheader>
    <!-- LANGUAGE -->
    <v-list class="sidebar-user-list">
      <v-list-item>
        <locale-changer
          extended
        ></locale-changer>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-subheader>Zoom</v-subheader>
    <v-list class="sidebar-user-list">
      <v-list-item>
        <v-slider
          v-model="zoom"
          class="slider-zoom pa-0"
          append-icon="zoom_in"
          prepend-icon="zoom_out"
          step="0"
          :min=".8"
          max="1"
          hide-details
          @click:prepend="() => zoom = .8"
          @click:append="() => zoom = 1"
        />
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- DARK MODE -->

    <v-list class="sidebar-user-list">
      <v-list-item>
        <v-switch
          v-model="darkMode"
          :label="$t('settings.darkMode')"
        />
      </v-list-item>
    </v-list>
    <!--  DEBUG MENU -->

    <v-list
      v-if="processEnv === 'development'"
      class="sidebar-user-list"
    >
      <v-list-item>
        <v-btn
          @click="breakToken()"
        >
          Break token
        </v-btn>
        <v-btn
          @click="breakRefreshToken()"
        >
          Break refresh token
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import LocaleChanger from "@/components/common/LocaleChanger"
  import {mapActions} from 'vuex'


  export default {
    name: 'SidebarRight',
    components: {LocaleChanger},
    methods: {
      ...mapActions(['breakToken','breakRefreshToken']),
    },
    computed: {
      processEnv() {
        return process.env.NODE_ENV
      },
      zoom: {
        get() {
          return this.$store.state.layout.zoom
        },
        set(value) {
          this.$store.commit('SET_ZOOM', value)
        },
      },
      drawer: {
        get() {
          return this.$store.state.layout.sidebarRight
        },
        set(value) {
          this.$store.commit('SET_SIDEBAR_RIGHT', value)
        },
      },
      darkMode: {
        get() {
          return this.$store.state.layout.dark
        },
        set(val) {
          this.$store.commit('SET_DARK_MODE', val)
          this.$vuetify.theme.dark = val
        },
      },
    },
  }
</script>
