import auth from "@/store/actions/auth"
import layout from "@/store/actions/layout"
import stock from "@/store/actions/stock"
import index from "@/store/actions/index"
import watch from "@/store/actions/watch"
import funds from "@/store/actions/funds"
import bonds from "@/store/actions/bonds"

export default {
  ...auth,
  ...layout,
  ...stock,
  ...index,
  ...watch,
  ...funds,
  ...bonds,
}
