<template>
  <v-app>
    <AppBar v-if="loggedIn " />
    <SidebarLeft v-if="loggedIn" />
    <SidebarRight v-if="loggedIn" />
    <v-main :class="!loggedIn || 'main'">
      <router-view
        :style="{zoom:layout.zoom}"
      />
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
  import AppBar from "./components/layout/AppBar"
  import SidebarLeft from "./components/layout/SidebarLeft"
  import SidebarRight from './components/layout/SidebarRight'
  import { mapState } from 'vuex'
  import Snackbar from "@/components/layout/Snackbar"

  export default {
    name: 'App',

    components: {
      Snackbar,
      SidebarLeft,
      AppBar,
      SidebarRight,
    },

    computed: {
      ...mapState(['layout']),
      loggedIn() {
        return this.$store.getters.isLoggedIn
      },
      route() {
        return this.$route.path
      },
    },

    watch: {
      route() {
        this.checkLogin()
      },
    },

    mounted() {
      this.checkLogin()
      this.$vuetify.theme.dark = this.layout.dark
    },

    methods: {
      checkLogin() {
        if (!this.loggedIn && this.$route.path !== '/login') {
          this.$router.push('/login')
        }
      },
    },
  }
</script>

<style lang="scss">

//base padding for <v-main>
.main {
  > .v-main__wrap {
    padding: 30px
  }
}

</style>
