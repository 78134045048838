import auth from "@/store/mutations/auth"
import layout from "@/store/mutations/layout"
import stock from "@/store/mutations/stock"
import i18n from "@/store/mutations/i18n"

export default {
  ...auth,
  ...layout,
  ...stock,
  ...i18n,
}
