import Vue from 'vue'
import App from './App.vue'
import getRouter from '@/config/router'
import store from './store/store'
import CountryFlag from 'vue-country-flag'
import VueI18n from "vue-i18n"

import vuetify from './config/vuetify'
import messages from './i18n/i18n'


Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: store.state.locale, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

Vue.filter('localDate', function(value) {
  if (!value) {
    return '---'
  } else {
     return new Date(value).toLocaleDateString(store.state.locale)
  }
})

new Vue({
  router: getRouter(store),
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
