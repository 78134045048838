export default {
  TOGGLE_SIDEBAR_LEFT(state) {
    state.layout.sidebarLeft = !state.layout.sidebarLeft
  },
  TOGGLE_SIDEBAR_RIGHT(state) {
    state.layout.sidebarRight = !state.layout.sidebarRight
  },
  SET_SIDEBAR_LEFT(state, value) {
    state.layout.sidebarLeft = value
  },
  SET_SIDEBAR_RIGHT(state, value) {
    state.layout.sidebarRight = value
  },
  SET_ZOOM(state, value) {
    state.layout.zoom = value
  },
  SET_DARK_MODE(state, value) {
    state.layout.dark = value
  },
  SHOW_SNACKBAR(state, data) {
    state.layout.snackbar = {
      text: data.text,
      type: data.type,
      icon: data.icon,
      customIcon: data.customIcon,
      show: true,
    }
  },
  HIDE_SNACKBAR(state) {
    state.layout.snackbar = {
      text: null,
      show: false,
    }
  },
}
