<template>
  <div>
    <v-btn
      v-for="language in languages"
      :key="language.id"
      rounded
      :outlined="$store.state.locale === language.id"
      :icon="!extended"
      class="country-button mr-2 elevation-0"
      @click="changeLocale(language.id)"
    >
      <country-flag
        :country="language.flag"
      />
      <span
        v-if="extended"
        class="label"
      >
        {{ language.id }}
      </span>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'LocaleChanger',
    props: {
      extended: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        languages: [
          {
            id: 'de',
            full: 'german',
            flag: 'de',
          },
          {
            id: 'en',
            full: 'english',
            flag: 'gb',
          },
        ],
      }
    },
    methods: {
      changeLocale(lang) {
        this.$store.commit('SET_LOCALE', lang)
        this.$vuetify.lang.current = lang
        this.$i18n.locale = lang
      },
    },
  }
</script>

<style lang="scss">

.country-button {
  &.v-btn--outlined {
    &.theme--dark {
      border-color: #666; // HAIL!
      background-color: #555;
    }
    &.theme--light {
      border-color: #999; // HAIL!
      background-color: #ccc;
    }
  }

  .label {
    margin-left: 5px;
  }

  .v-tbn__content {
    height: 40px;
  }
}
</style>
