<template>
  <v-navigation-drawer
    clipped
    app
    permanent
    :mini-variant="$vuetify.breakpoint.mdAndDown"
    :expand-on-hover="$vuetify.breakpoint.mdAndDown"
    class="sidebar-left"
  >
    <v-list
      v-model="list"
      dense
    >
      <template v-for="item in filteredSidebar">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>

        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          :value="groupIsActive(item)"
          :prepend-icon=" item.icon "
          class="sidebar-left-item"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`sidebar.${item.text}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in filterChildren(item.children)"
            :key="i"
            link
            :to="child.link"
            class="sidebar-left-item"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`sidebar.${child.text}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.text"
          link
          active-class="sidebar-item-active"
          :to="item.link"
          class="sidebar-left-item"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(`sidebar.${item.text}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import sidebar from "@/config/sidebar"
  import { mapGetters } from "vuex"

  export default {
    name: 'SidebarLeft',
    data() {
      return {
        list: null,
      }
    },
    computed: {
      ...mapGetters(['user']),
      filteredSidebar() {
        var roles = this.user.roles['citrus-broker'].map(role => role.name)
        return sidebar.filter( el => el.roles.some(r => roles.includes(r)))
      },
      drawer: {
        set(value) {
          this.$store.commit('SET_SIDEBAR_LEFT', value)
        },
        get() {
          return this.$store.state.layout.sidebarLeft
        },
      },
    },
    methods: {
      groupIsActive(group) {
        return [...group.children.map(el => el.link)].includes(this.$route.path)
      },
      filterChildren(childs) {
        return childs.filter( el => el.roles.includes(this.user.roles['citrus-broker'][0].name))
      },
    },
  }
</script>

<style lang="scss">
.sidebar-left {
  .v-list-item.theme--dark {
    color: #fff !important
  }
}

.sidebar-item-active {
  //background-color:   #fff;
}

.sidebar-left-item {
  .v-list-item__action, .v-list-group__header__prepend-icon {
    margin: 16px 32px 16px 0 !important;
  }
}
</style>
