import de from 'vuetify/lib/locale/de'

const message = {
  ...de,
  login: {
    login: 'Einloggen',
    loginFailed: 'Fehler bei der Anmeldung',
  },
  home: {
    greeting: '',
    error: 'Beim Laden Ihrer Daten ist ein Fehler aufgetreten. Falls dieser weiterhin auftritt, wenden Sie sich bitte an den Support.',
  },
  settings: {
    darkMode: 'Dunkler Modus',
    language: 'Sprache',
  },
  sidebar: {
    dashboard: 'Dashboard',
    stocks: '@:stocks.stocks',
    indices: '@:indices.indices',
    watcher: '@:watcher.watchers',
    funds: '@:funds.funds',
    bonds: '@:bonds.bonds',
    bondsActivations: '@:bonds.activations',
    users: '@:user.users',
    equivalenceCheck: '@:ec.equivalenceCheck',
  },
  ec: {
    equivalenceCheck: 'Äquivalenzcheck',
    typeSelector: 'Produkttyp auswählen',
  },
  user: {
    user: 'Benutzer',
    users: 'Benutzer',
    username: 'Benutzername',
    password: 'Passwort',
    first: 'Vorname',
    last: 'Nachname',
    organization: 'Organisation',
    email: 'Email',
    createdAt: 'Erstellt',
    active: 'Aktiv',
    deleted: 'Gelöscht',
  },
  actions: {
    create: 'Erstellen',
    createX: `{0} erstellen`,
    add: 'Hinzufügen',
    addX: '{0} hinzufügen',
    save: 'Speichern',
    reset: 'Zurücksetzen',
    delete: 'Löschen',
    deleteX: '{0} löschen',
    search: 'Suchen',
    showOnlySelected: 'Nur ausgewählte anzeigen',
    update: 'Aktualisieren',
    upload: 'Hochladen',
    saved: 'Ihre Änderungen wurden gespeichert',
    error: 'Ein Fehler ist aufgetreten',
  },
  snackbar: {
    errorOnSave: 'Beim Speichern der Daten ist ein Fehler aufgetreten.',
  },
  stocks: {
    stocks: 'Aktien',
    stock: 'Aktie',
    productIsActive: 'Produkt ist aktiv',
    productStatusUnknown: 'Produktstatus ist (noch) unbekannt',
    productIsInactive: 'Produkt ist inaktiv. Gründe: ',
    useSearchBar: 'Benutze das Suchfeld um nach Aktienname, ISIN oder WKN zu suchen',
    industryField: 'Industriefeld',
    expirationDate: 'Ablaufdatum',
    deleteMessage: '<strong>Möchtest du wirklich die ausgewählte Aktie löschen?</strong><br> Das Löschen kann nicht rückgängig gemacht werden!',
  },
  funds: {
    funds: 'Fonds',
    searchLabel: 'Suchbegriff',
    assetClass: 'Anlageklasse',
    assetClasses: {
      all: "Alle Anlageklassen",
      BOND: "Rentenfonds",
      EQUITY: "Aktienfonds",
      MIXED: "Mischfonds",
      REAL_ESTATE: "Immobilienfonds",
      MONEY_MARKET: "Geldmarktfonds",
      LIFECYCLE: "Laufzeitfonds",
      COMMODITIES: "Rohstofffonds",
      GUARANTEE: "Garantiefonds",
      OTHERS: 'Andere',
    },
    reports: {
      documents: 'Dokumente',
      annual: 'Jahresreport',
      semiAnnual: 'Halbjahresreport',
      prospect: 'Prospekt',
      kid: 'BIB (PRIIP KID)',
      beiblatt: 'Beiblatt',
      factsheet: 'Factsheet',
      presentation: 'Präsentation',
      reportDate: 'Erstellungsdatum: ',
      selectFile: 'Datei wählen',
      openFile: 'Datei öffnen',
      removeFile: 'Datei entfernen',
      uploadSuccess: 'Datei "{0}" wurde erfolgreich hochgeladen',
      invalidateSuccess: '{0} wurde erfolgreich entfernt',
      selectDate: 'Reportdatum auswählen',
      uploadNewFile: 'Neue Datei hochladen',
      isCustomUpload: 'Dieses Dokument wurde manuell hochgeladen',
      isBasicUpload: 'Dieses Dokument wird von FundInfo bereitgestellt.',
      uploadError: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.',
    },
    fractions: {
      error: 'Beim Speichern der Daten ist ein Fehler aufgetreten',
      saved: 'Produktdaten wurden gespeichert',
      sumCondition: 'Die Summe muss 100% betragen.',
      total: 'Gesamt',
      liquidity: 'Liquidität',
      bonds: 'Renten',
      stocks: 'Aktien',
      real_estate: 'Immobilienfonds',
      commodities: 'Rohstoffe',
      holdings: 'Beteiligungen',
      others: 'Weitere',
      fractions: 'Mischprodukt',
    },
    sales: {
      support: 'Vertriebsunterstützung',
      comment: 'Fondsmanagerkommentar (Quelle: Asset Manager)',
      investmentStrategy: 'Anlagestrategie (Quelle: Asset Manager)',
      contacts: 'Ansprechpartner',
      files: 'Dateien',
      addContact: 'Ansprechpartner hinzufügen',
      label: 'Beschriftung',
      labelPrompt: 'Beschriftung angeben',
    },
    ec: {
      equivalenceCheck: 'Äquivalenzcheck',
      saved: 'Äquivalenzdaten gespeichert',
      bviFundCategory: 'BVI Fonds-Kategorie Feingruppen',
      currencyHedge: 'Währungssicherung',
      dq: 'DQ',
      objective: 'Ziel',
      capitalProtection: 'Zielrendite: Wertuntergrenze',
      targetYield: 'Zielrendite: Ertragsziel (Positiv)',
      recommendedHoldingPeriod: 'Empfohlene Haltedauer',
      riskBasedManagement: 'Risikoorientierter Managementansatz',
      stockNeutralPercentage: 'Aktienneutralquote (Mischfonds)',
      strategy: 'Strategie',
      fundamental: 'Fundamentales Management',
      quantitative: 'Quantitatives Management',
      thematic: 'Thematische Anlagestrategie',
      sustainability: 'Nachhaltigkeit',
      sustainabilityInfo: 'Angabe zur Nachhaltigkeit',
      sustainabilityRiskInfo: 'Nachhaltigkeits-Risikoinfo',
    },
  },
  bonds: {
    bonds: 'Anleihen',
    activations: 'Bond Aktivierungen',
    saved: 'Die Anleihe wurde gespeichert',
    saveError: 'Beim Speichern der Anleihe ist ein Fehler aufgetreten',
    startSubscriptionDate: 'Start Zeichnungsphase',
    endSubscriptionDate: 'End Zeichnungsphase',
    searchError: 'Anleihe konnte nicht gefunden werden',
    price: 'Einstandspreis',
    issueSurcharge: 'Ausgabeaufschlag',
    isin: 'ISIN',
    issuer: 'Emittent',
    name: 'Name',
    issueDate: 'Emissionstag',
    intermediary: 'Intermediär',
    tailorMade: 'Tailormade',
    month: 'Monat',
    year: 'Jahr',
    filter: 'Emissionstag Filtern',
  },
  indices: {
    indices: 'Indizes',
    index: 'Index',
    listedStocks: 'Gelistete Aktien',
    addStockHint: 'DQ direkt eingeben oder nach Name/WKN/ISIN suchen',
    invalidInput: 'Ungültige Eingaben! Bitte überprüfen Sie, ob Name, ISIN und WKN richtig gesetzt sind.',
    productExistsAlready: 'Fehler: Ein Produkt mit dieser DQ existiert bereits!',
    successfulCreation: 'Index "{0}" (DQ: {1}) erfolgreich erstellt!',
    errorOnCreation: 'Beim Erstellen des Index ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    successfulDelete: 'Index erfolgreich gelöscht!',
    errorOnDelete: 'Fehler beim Löschen des Index. Bitte versuchen Sie es erneut.',
    successfulUpdate: 'Index "{0}" erfolgreich aktualisiert!',
    errorOnUpdate: 'Beim Aktualisieren des Index ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    deleteMessage: '<strong>Soll der ausgewählte Aktienindex wirklich gelöscht werden?</strong><br> Das Löschen kann nicht rückgängig gemacht werden!',
  },
  watcher: {
    watchers: 'Watcher',
    watcher: 'Watcher',
    instrumentType: 'Instrumenttyp',
    validUntil: 'Gültig bis',
    ignoreUntil: 'Ignorieren bis',
    productWatcherInformation: 'Produkt-/Watcherinformation',
    productStateInfo: 'Produktstatus: {0} (letzte Überprüfung: {1})',
    productState: 'Produktstatus',
    latestCheck: 'Letzter Check',
    productType: 'Produkttyp',
    productMessage: 'Produktnachricht',
    modeDescriptions: ['Äquivalenzdaten vorhanden [FONDS]', 'Zertifikats-Typ bekannt [DERIVATE]'],
    invalidInput: 'Ungültige Eingaben! Bitte überprüfen Sie die DQ und sämtliche Datumseingaben.',
    successfulCreation: 'Watcher erfolgreich erstellt!',
    errorOnCreation: 'Beim Erstellen des Produkt-Watchers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    deleteMessage: '<strong>Soll der ausgewählte Produkt-Watcher wirklich gelöscht werden?</strong><br> Das Löschen kann nicht rückgängig gemacht werden!',
    successfulDelete: 'Watcher erfolgreich gelöscht!',
    errorOnDelete: 'Fehler beim Löschen des Watchers. Bitte versuchen Sie es erneut.',
    successfulUpdate: 'Watcher erfolgreich aktualisiert!',
    errorOnUpdate: 'Beim Aktualisieren des Produkt-Watchers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  },
  search: {
    search: 'Suchen',
    results: 'Suchergebnisse',
    noResultsText: 'Keine {0} gefunden.',
  },
  validation: {
    notEmpty: 'Der Wert darf nicht leer sein',
    notEmptyX: '{0} darf nicht leer sein',
    containXCharacters: '{0} muss {1} Zeichen lang sein',
    invalidFormat: 'Ungültiges Format',
  },
  notification: {
    loading: 'Daten werden geladen..',
    noProductsFound: 'Keine Daten gefunden! Bitte passen Sie Ihre Suche an und versuchen es erneut.',
  },
  months: {
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
  },
  country: 'Land',
  currency: 'Währung',

  yes: 'Ja',
  no: 'Nein',
}

export default message
