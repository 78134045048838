import axios from 'axios'

export default {
  fetchAllIndices() {
    const url = '[citrus-broker]/app/deristock/index/'
    return axios.get(url)
  },

  addIndex(context, index) {
    const url = '[citrus-broker]/app/deristock/index/insert'
    return axios.post(url, [index])
  },

  fetchSingleIndex(context, dq) {
    const url = `[citrus-broker]/app/deristock/index/${dq}`
    return axios.get(url)
  },

  deleteIndex(context, dq) {
    const url = `[citrus-broker]/app/deristock/index/${dq}`
    return axios.delete(url)
  },

  reactivateIndex(context, dq) {
    const url = `[citrus-broker]/app/deristock/derivation/index/${dq}/activate`
    return axios.get(url)
  },
}
